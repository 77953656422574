import { useMutation, useQuery } from '@vue/apollo-composable'
import { gql } from '@apollo/client'

export function getList({ page, pageSize }: { page: number; pageSize: number }) {
  const { account } = useUserStore()
  const query = gql`
      query getFeeds($page: Int!, $pageSize: Int!, $userId: ID!) {
        feeds(pagination: { page: $page, pageSize: $pageSize }, filters: { link: { owner: { id: { eq: $userId } } } }, sort: ["createdAt:desc"]) {
          data{
            id
            attributes {
              Action
              link {
                data {
                  id
                  attributes {
                    recipient_name
                    recipient_domain
                    page_name
                  }
                }
              }
              Details
              metadata
              createdAt
            }
          }
          meta{
            pagination{
                total
                pageCount
            }
          }
        }
      }
    `
  const variables = { page, pageSize, userId: account?.id }
  return useQuery(query, variables, { prefetch: false })
}

export function createNew({ linkId, action, details, metadata }: { linkId: string; action: string; details: string; metadata?: IFeedMetadata }) {
  const mutation = gql`
      mutation createFeed ($linkId: ID!, $action: String, $details: String, $metadata: JSON, $publishedAt: DateTime){
        createFeed(data: { link: $linkId, Action: $action, Details: $details, metadata: $metadata, publishedAt: $publishedAt }) {
          data {
            id
            attributes {
              Action
              Details
              link {
                data {
                  id
                  attributes {
                    recipient_name
                    recipient_domain
                    page_name
                  }
                }
              }
              publishedAt
            }
          }
        }
      }
    `
  const variables = { linkId, action, details, metadata, publishedAt: new Date() }
  return useMutation(mutation, { variables })
}
